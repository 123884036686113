.download-notification {
	display:none;
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #f8f9fa;
    color: #343a40;
    text-align: center;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	}
	.border-hover{background: black; padding:7px; border-radius:28px; margin-bottom:25px; position:relative; overflow:hidden}
	.grad {position:absolute;top:0; left:0; width:100%; height:100%; background: linear-gradient(131deg, #e70489, #ff8856); opacity:0; transition:0.2s}
	.border-hover:hover .grad{opacity:1;}
	.border-hover .border-inner {background:white; border-radius:23px; padding:15px 25px; position:relative; z-index:2}
	.border-hover svg {width:300px}

.download-notification p {
    font-size: 24px;
}

.download-notification a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    margin: 10px;
}

.download-notification a:hover {
    text-decoration: underline;
}

.download-notification img {
    height: 120px;
    verticalAlign: middle;
    margin: 0 10px;
}

@media screen and (min-width: 768px) {
    .download-notification {
        display: flex;
    }
}
