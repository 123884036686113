.lightbox.report-box {
    visibility: visible;
    opacity: 1;
}
#report_reason_text{
	border-radius: 4pt;
    border: 1pt solid #e5e5e5;
    width: calc(100% - 20pt);
    box-sizing: border-box;
    padding: 5pt;
}