.gradientable8{fill:url(#MyGradient8)}
.lightbox.delete_my_acc {
    visibility: visible;
    opacity: 1;
}

.edit_age {
    display: flex;
    justify-content: space-between;
}

.edit_age select {
    width: 33% !important;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
    text-indent: inherit !important;
}