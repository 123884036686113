section.camera-viewer > section.result {
    position: fixed;
    z-index: 4;

    width: 100dvw;
    height: 100svh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

section.result > div.splash {
    position: absolute;

    width: 100%;
    height: 100%;

    animation: white-splash 300ms ease-out forwards;

    pointer-events: none;
}

section.result > div.reactEasyCrop_Container {
    z-index: -1;

    object-fit: cover;

    width: 100%;
    height: 100%;
    background-color: black;
}

section.result > .video-result {
    position: absolute;
    z-index: -1;

    object-fit: cover;

    width: 100%;
    height: 100%;
}

section.result > .top-nav {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.result > .top-nav > img {
    width: 1.5rem;
    height: 1.5rem;
    filter: invert(1);
}

section.result > .top-nav > button {
    width: fit-content;
    padding: 0.75rem 1.5rem;

    font-weight: bold;
    color: black;

    background: white;
    border-radius: 25px;
}

section.result > div.comment {
    padding: 2.5rem;
    height: 7rem;
    background: rgba(0, 0, 0, 0.4);
}

section.result > div.comment > h5 {
    color: white;
    font-weight: 400;
    margin: 5px;
}

section.result > div.comment > textarea {
    transition: 0.2s;
    padding: 0.5rem;
    color: white;
    outline: none;
    border-radius: 10px;
    border: 1px solid #ffffff6e;
    background: #ffffff2e;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 1rem);
}

@keyframes white-splash {
    from {
        background-color: white;
    }
    to {
        background-color: transparent;
    }
}
