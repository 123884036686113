@import url('result.css');

:root {
    --camera-background: #0000007d;
}

.camera-viewer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    width: 100dvw;
    height: 100dvh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.camera-viewer > video {
    height: 100svh;
    width: 100%;
}

.camera-viewer > div.selector {
    position: absolute;
    width: 100%;

    bottom: 0;
    z-index: 2;
    padding: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15dvw;
}

.camera-viewer > div.selector {
    top: 0;
    bottom: auto;
    column-gap: 25dvw;
}

.camera-viewer > div.selector > div {
    padding: 0 3rem;
    padding-bottom: 0.75rem;

    font-weight: 500;
    color: white;

    border-bottom: 1px solid;
    transition: all 300ms;
}

.camera-viewer > div.photo-loader {
    position: absolute;
    z-index: 3;
    top: 7rem;
    right: 2rem;

    width: 2rem;
    height: 2rem;
}

.camera-viewer > div.photo-loader > input::file-selector-button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: transparent;
    border: none;
}

.camera-viewer > div.photo-loader > img {
    width: 100%;
    height: 100%;
    z-index: 5;
}

.camera-viewer > div.photo-loader > input {
    position: absolute;
    width: 100%;
    height: 100%;
}

.camera-viewer > div.controller {
    position: absolute;
    width: calc(100% - 4rem);

    bottom: 0;
    z-index: 2;
    padding: 2rem;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    row-gap: 2rem;
}

.camera-viewer > .controller > div.timer {
    grid-column: 1 / 4;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    column-gap: 0.5rem;
}

.camera-viewer > .controller > div.timer > h2 {
    color: white;
    font-weight: 400;
}

.camera-viewer > .controller > div.timer > .line {
    width: 65dvw;

    height: 3px;
    border-radius: 5px;

    background: linear-gradient(90deg, white 0%, #b8b8b8a6 0%);
}

.camera-viewer > div.controller > button.close-capture,
.camera-viewer > div.controller > button.change-camera {
    position: relative;

    width: 50px;
    height: 50px;

    background: var(--camera-background);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.camera-viewer > div.controller > button img {
    padding: 1rem;
    width: 40%;
    height: 40%;
    filter: invert(1);
}

.camera-viewer > div.controller > button.change-camera img {
    width: 60%;
    height: 60%;
}

.camera-viewer > div.controller > button.take-photo {
    position: relative;

    width: 75px;
    height: 75px;

    border: 4px solid white;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 300ms;
}
