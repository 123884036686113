section.photo-editor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    width: 100dvw;
    height: 100svh;

    background-color: gray;

    animation: editor 250ms ease-in-out forwards;
}

section.photo-editor > div.buttons {
    position: fixed;
    bottom: 0;
    left: 0;

    padding-bottom: 3rem;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

section.photo-editor > div.buttons > div {
    width: 5rem;
    height: 5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
}

section.photo-editor > div.buttons > div > img {
    width: 2rem;
    height: auto;
}

section.photo-editor .reactEasyCrop_CropArea {
    border: 4px solid #ff0067;
}

@keyframes editor {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
