#registration form {
    display: flex;
    flex-direction: column;
}

span#reg_error {
    margin-top: 1rem;
    padding: 1rem;
    color: white;
    background-color: #ff4064;
    animation: error 2s ease-in-out;
}

@keyframes error {
    15% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }
}
