.gradientable5132 > {
    fill: url(#MyGradient5132);
}
.gradientable4132 {
    fill: url(#MyGradient4132);
}
.favourites svg,
.user_favourites svg {
    transition: 0.2s;
}

.dislike_liked > svg {
    fill: url(#dislike-gradient);
}

div.favourites > svg {
    fill: white;
}

div.favourites.active > svg {
    fill: url(#favourite-gradient);
}

@keyframes pop {
    50% {
        transform: scale(1.3);
    }

    75% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}
