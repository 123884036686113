section.audio-comments {
    position: absolute;
    top: 0;
    z-index: 2;

    width: 100dvw;
    height: 100svh;

    background-color: white;

    overflow-y: scroll;

    animation: slide-top ease-in-out 400ms forwards;
}

section.audio-comments > h3 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    color: #c5d4e4;
    font-weight: 300;
}

section.audio-comments > img.close {
    margin: 1.75rem;
}

section.audio-comments > div.record {
    position: fixed;
    bottom: 0;

    width: 100%;

    transition: all 500ms;
}

section.audio-comments > div.record > img.record-background {
    filter: drop-shadow(0px -1px 5px rgba(115, 115, 215, 0.2));
}

section.audio-comments > div.record > button {
    position: absolute;
    bottom: 5rem;
    left: 0;
    right: 0;
    margin-inline: auto;

    width: 3.5rem;
    height: 3.5rem;
}

section.audio-comments > div.record > button > img {
    width: 2rem;
    height: 2rem;
}

section.audio-comments > div.record > button > div {
    width: 1.25rem;
    height: 1.25rem;
    background-color: white;
}

section.audio-comments > div.record > h2 {
    position: fixed;
    bottom: 2rem;
    left: 0;
    right: 0;
    margin-inline: auto;
    width: fit-content;
}

section.audio-comments > div.comment {
    padding: 0.5rem 1.25rem;

    display: grid;
    grid-template-columns: 3rem 1fr;
    gap: 0.5rem 0.75rem;
}

section.audio-comments > div.comment > img {
    width: 3rem;
    height: 3rem;

    grid-row: 1 / 4;

    border-radius: 50%;
}

section.audio-comments > div.comment > div.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.audio-comments > div.comment > div.top > h5 {
    color: #00000057;
    font-weight: 300;
}

section.audio-comments > div.comment > div.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.audio-comments > div.comment > div.bottom > h5 {
    color: grey;
}

section.audio-comments > div.comment > div.bottom > .likes {
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    column-gap: 1rem;
}

section.audio-comments > div.comment > div.bottom > .likes > img {
    width: 1.75rem;
}

section.audio-comments > div.comment > .sound {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.audio-comments > div.comment > .sound > canvas {
    width: 80%;
    background-color: rgba(200, 200, 200, 0.01);
    border-radius: 10px;
}

section.audio-comments > div.comment > .sound > button {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
}

section.audio-comments > div.comment > .sound > button > img {
    width: 50%;
    height: 50%;
}

section.audio-comments > div:nth-last-child(2) {
    padding-bottom: 5rem;
}

#round-gradient {
    border-radius: 50%;
    background: linear-gradient(131deg, #e70489, #ff8856);
}

@keyframes liked {
    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slide-top {
    from {
        transform: translateY(100svh);
    }

    to {
        transform: translateY(0);
    }
}
