video {
  background: black;
  width: 100vw;
  height: 100vh;
}
#result_container {
	display:flex;
	background:black;
	z-index: 1;
	visibility:hidden;
	opacity:0;
	transition:0.2s;
    top: 10%;
    position: absolute;
    left: 10%;
    width: 80%;
    height: 80%;
	}
#result_container .top_nav {padding:15pt}
#result_container .top_nav svg {max-width:17pt}
.video_result .bottom_nav {
	color:white;
	position: absolute;
    bottom: 80pt;
    height: 50pt;
    left: 10%;
    width: 80%;
}
.photo_result img {width:100%; margin:auto 0}
.photo_result .bottom_nav {
	color:white;
	position: absolute;
    bottom: 40pt;
    height: 50pt;
    left: 10%;
    width: 80%;
}
#result_container .bottom_nav span {
	font-size:8pt;
	padding:2pt 5pt;
}
#result_container .bottom_nav textarea{
	transition:0.2s;
	padding: 5pt;
    color: white;
	outline:none;
	border-radius: 6pt;
    border: 1pt solid #ffffff6e;
    background: #ffffff2e;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
#result_container .bottom_nav textarea:focus {border: 1pt solid white;}
#playback {width:100%; height:100%}
#controls {
    height: 100pt;
    bottom: 0;
    padding: 0 15pt 30pt 15pt;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
}
#controls .video_indicator{
	opacity:0;
	height: 10pt;
	display: flex;
    margin-bottom: 10pt;
    width: 90%;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}
#controls .video_indicator .bar{
	border-radius: 1pt;
    width: calc(100% - 35pt);
    height: 2pt;
    background: linear-gradient(90deg, white 0%, #b8b8b8a6 0%);

}
#controls .video_indicator div:last-child{
	color: white;
    width: 35pt;
    text-align: center;
}
.switcher {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50pt;
    display: flex;
 	color: #777777;
	font-weight: bold;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-around;
}
.switcher div {
	transition:0.2s;
	text-align: center;
    width: 35%;
    margin-bottom: 10pt;
    padding: 25px 0pt 15pt;
    border-bottom: 1pt solid transparent;}
#controls * {outline:none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);}
#controls .small{
	position: relative;
	width:38pt;
	height:38pt
}
#controls .small .close-capture {padding:13pt!important}
#controls .small img, #controls .small button, #controls .small .close-capture {width:100%; height:100%; box-sizing: border-box; overflow:hidden; border-radius:50%}
#controls .small button, #controls .small img, #controls .big button, #controls .small .close-capture {position:absolute}
#controls .big{
	position: relative;
	width:60pt;
	height:60pt
}
#controls #snapshot, #controls #rec {border: 3pt solid white; transition:0.2s}
#controls #snapshot {background: transparent;}
#controls #rec {background: red;}
#controls #stop {
	padding: 20pt;
	background:white;
}
#controls #stop div {
	border-radius: 3pt;
    width: 100%;
    background: red;
    height: 100%;
	}
#controls .third_button_block button, #controls .first_button_block button, #controls .first_button_block .close-capture {padding:10pt; background:#0000007d}
#controls .third_button_block svg, #controls .first_button_block svg {fill:white}
button {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}
button:hover, button:focus {
  outline: none;
  background: #c72d1c;
}
button::-moz-focus-inner {
  border: 0;
}
button:active {
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
  line-height: 3rem;
}
button:disabled {
  pointer-events: none;
  background: lightgray;
}
button:first-child {
 
}
video{
  width: 1280px;
  height: 720px;
}
.video_container {
	position: relative;
    width: 100vw;
    height: 100vh;
}
.video_container video {
	position: absolute;
    width: 100%;
    height: 100%;
}
/* Interactive UI*/
#pauseRes, #stop, .first_button_block img, #photo, #video {transition:0.2s}
#pauseRes, #stop {visibility:hidden}